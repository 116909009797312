import React from "react"
import Layout2 from "../components/main/layout2"
import Account from "../components/main/userAccount"

const AccountPage = () => {
  return (
    <Layout2>
      <Account />
    </Layout2>
  )
}

export default AccountPage
