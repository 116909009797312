import React, { Component } from "react"
import { Link, navigate } from "gatsby"
import styled from "styled-components"
import { withFirebase } from "./firebase"
import { CompanyLogo } from "../../lib/componentHelpers"
import * as ROUTES from "../../constants/routes"

const PassDiv = styled.div`
  margin-top: 0;
  width: 100%;
  height: 100%;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #19232d;
`

const Form = styled.form`
  margin-top: 2em;
  width: 310px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #19232d;
  color: #ffffff;
`

const HeaderH2 = styled.h2`
  width: 100%;
  height: 120px;
  font-family: Noe Display;
  font-size: 20px;
  line-height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
`

const Input = styled.input`
  width: 100%;
  height: 33px;
  font-size: 16px;
  font-family: "overpass";
  margin-bottom: 1em;
  align-self: center;
  border: none;
  border-bottom: 1px solid #262c47;
  background-color: transparent;
  color: #ffffff;
  padding: 5px;
  outline: none;
  ::placeholder {
    color: #ffffff;
  }
`

const Button = styled.button`
  width: 100%;
  background-color: #fbffa0;
  color: #19232d;
  font-size: 14px;
  font-weight: 900;
  border: none;
  text-transform: uppercase;
  padding: 15px;
  align-self: center;
  margin-top: 2em;
  &:hover {
    cursor: pointer;
    background-color: #ffffff;
    color: #19232d;
    border: 1px solid #19232d;
  }
`

const ChangePassWordButton = styled.button`
  background-color: #fbffa0;
  color: white;
  font-size: 14px;
  font-weight: 900;
  border: 1px solid #fbffa0;
  text-transform: uppercase;
  padding: 5px;
  align-self: center;
  margin: 0 0 20px 0;
  color: #19232d;
  &:hover {
    cursor: pointer;
    border: 1px solid #19232d;
    color: #19232d;
    background-color: #ffffff;
  }
`

const ChangePassWordLink = styled(Link)`
  text-decoration: none;
`

const PasswordChangeP = styled.p`
  width: 100%;
  display: grid;
  font-family: "overpass";
  font-size: 16px;
  font-weight: bold;
`

const BSLogoDiv = styled.div`
  margin-top: 9em;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 560px) {
    display: none;
  }
`

const INITIAL_STATE = {
  oldPassword: "",
  newPasswordOne: "",
  newPasswordTwo: "",
  error: null
}

class PasswordChangeFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  handlePasswordChange = event => {
    event.preventDefault()

    const { oldPassword, newPasswordOne, newPasswordTwo } = this.state

    // TODO: Verify the Old Password

    !oldPassword.replace(/\s/g, "").length
      ? this.setState({ error: `Die eingegebenen Passwörter stimmen nicht überein` })
      : newPasswordOne === newPasswordTwo
      ? this.props.firebase
          .doPasswordUpdate(newPasswordOne)
          .then(() => {
            this.setState({ ...INITIAL_STATE })
            navigate(ROUTES.ACCOUNT)
          })
          .catch(err => {
            if (err.code === "auth/weak-password")
              this.setState({ error: `Das Passwort sollte mindestens 6 Zeichen lang sein` })
            else if (err.code === "auth/requires-recent-login")
              this.setState({
                error: `Dieser Vorgang ist vertraulich. Bitte melden Sie sich erneut an, bevor Sie diese Anfrage wiederholen.`
              })
            else this.setState({ error: err.message })
          })
      : this.setState({ error: `Die eingegebenen Passwörter stimmen nicht überein` })
  }

  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value })
  }

  render() {
    const { oldPassword, newPasswordOne, newPasswordTwo, error } = this.state

    return (
      <PassDiv>
        <Form data-form-id="passwordChangeComp" onSubmit={this.handlePasswordChange}>
          {error ? <HeaderH2>{error}</HeaderH2> : <HeaderH2>Ändern Sie Ihr Passwort</HeaderH2>}

          <Input
            name="oldPassword"
            type="password"
            placeholder="Altes Passwort"
            data-input-id="oldPassword"
            value={oldPassword}
            onChange={this.handleInputChange}
            autoComplete="old-Password"
          />
          <Input
            name="newPasswordOne"
            type="password"
            placeholder="Neues Passwort"
            data-input-id="newPasswordOne"
            value={newPasswordOne}
            onChange={this.handleInputChange}
            autoComplete="first-new-password"
          />
          <Input
            name="newPasswordTwo"
            type="password"
            placeholder="Bestätigen Neues Passwort"
            data-input-id="newPasswordTwo"
            value={newPasswordTwo}
            onChange={this.handleInputChange}
            autoComplete="second-new-password"
          />
          <Button
            id="passwordChangeSubmitButton"
            data-button-id="passwordChangeSubmitButton"
            type="submit"
          >
            Ändere mein Passwort
          </Button>
        </Form>

        <BSLogoDiv>
          <CompanyLogo />
        </BSLogoDiv>
      </PassDiv>
    )
  }
}

const PasswordChangeLink = () => (
  <PasswordChangeP>
    <ChangePassWordLink to={ROUTES.CHANGE_PASSWORD}>
      <ChangePassWordButton>Aktuelles Passwort ändern</ChangePassWordButton>
    </ChangePassWordLink>
  </PasswordChangeP>
)

const PasswordChangeForm = withFirebase(PasswordChangeFormBase)

export { PasswordChangeForm, PasswordChangeLink }
