import React, { Component } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { withFirebase } from "./firebase"
import * as ROUTES from "../../constants/routes"

const H3 = styled.h3`
  font-weight: bold;
  font-family: "overpass";
`

const UL = styled.ul`
  list-style-type: square;
`

const Button = styled.button`
  background-color: #3b9c46;
  color: #ffffff;
  font-size: 14px;
  font-weight: 900;
  border: 1px solid #3b9c46;
  text-transform: uppercase;
  padding: 5px;
  align-self: center;
  margin: 0 0 20px 0;
  outline: none;
  &:hover {
    cursor: pointer;
    border: 1px solid #19232d;
    color: #19232d;
    background-color: #ffffff;
  }
`

const Button2 = styled.button`
  background-color: #ff0000;
  color: white;
  font-size: 14px;
  font-weight: 900;
  border: 1px solid #ff0000;
  text-transform: uppercase;
  padding: 5px;
  align-self: center;
  margin: 0 0 20px 0;
  outline: none;
  &:hover {
    cursor: pointer;
    border: 1px solid #19232d;
    color: #19232d;
    background-color: #ffffff;
  }
`

const Form = styled.form`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const Input = styled.input`
  border: none;
  font-size: 20px;
  font-family: "overpass";
  outline: none;
  align-self: left;
  border-bottom: 1px solid #808080;
  padding: 5px;
`

const AnonButton = styled.button`
  background-color: #3b9c46;
  color: #ffffff;
  font-weight: 900;
  border: 3px solid #3b9c46;
  text-transform: uppercase;
  padding: 5px;
  align-self: center;
  margin-top: 0.5rem;
  outline: none;
  &:hover {
    cursor: pointer;
    border: 3px solid #19232d;
    color: #19232d;
    background-color: #ffffff;
  }
`

const AnonError = styled.p`
  font-family: Noe Display;
  font-size: 16px;
  color: #ff0000;
`

const SIGN_IN_METHODS = [
  {
    id: "google.com",
    provider: "googleProvider"
  },
  {
    id: "facebook.com",
    provider: "facebookProvider"
  },
  {
    id: "password",
    provider: "emailAuthProvider"
  }
]

class LoginManagementBase extends Component {
  _initFirebase = false

  constructor(props) {
    super(props)

    this.state = {
      activeSignInMethods: [],
      error: null
    }
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true

      this.fetchSignInMethods()
    }
  }

  componentDidMount() {
    this.firebaseInit()
  }

  componentDidUpdate() {
    this.firebaseInit()
  }

  componentWillUnmount() {
    this._initFirebase = false
  }

  fetchSignInMethods = () => {
    this.props.authUser.email
      ? this.props.firebase.auth
          .fetchSignInMethodsForEmail(this.props.authUser.email)
          .then(activeSignInMethods => {
            this.setState({ activeSignInMethods, error: null })
          })
          .catch(err => this.setState({ error: err.message }))
      : this.props.firebase.auth
          .signInAnonymously()
          .then(activeSignInMethods =>
            this.setState({
              activeSignInMethods: activeSignInMethods.push({
                id: "anonymous",
                provider: null
              }),
              error: null
            })
          )
          .catch(err => this.setState({ error: err.message }))
  }

  handleSocialLoginLink = provider => {
    this.props.firebase.auth.currentUser
      .linkWithPopup(this.props.firebase[provider])
      .then(this.fetchSignInMethods)
      .catch(err => this.setState({ error: err.message }))
  }

  onDefaultLoginLink = (email, password, username) => {
    const credential = this.props.firebase.emailAuthProvider.credential(email, password)

    return this.props.firebase.auth.currentUser
      .linkWithCredential(credential)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set({
          username,
          email
        })
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification()
      })
      .then(() => {
        alert(`Anonymous account successfully upgraded. Activation email sent.`)
        navigate(ROUTES.SIGN_IN)
      })
      .catch(err => this.setState({ error: err.message }))
  }

  onUnlink = providerId => {
    return this.props.firebase.auth.currentUser
      .unlink(providerId)
      .then(this.fetchSignInMethods)
      .catch(err => this.setState({ error: err.messasge }))
  }

  render() {
    const { activeSignInMethods, error } = this.state

    return (
      <div>
        <H3>Manage Sign In Methods:</H3>
        <UL>
          {SIGN_IN_METHODS.map(signInMethod => {
            const onlyOneLeft = activeSignInMethods.length === 1
            const isEnabled = activeSignInMethods.includes(signInMethod.id)

            return (
              <li key={signInMethod.id}>
                {signInMethod.id === "password" ? (
                  <EmailLoginToggle
                    onlyOneLeft={onlyOneLeft}
                    isEnabled={isEnabled}
                    signInMethod={signInMethod}
                    onLink={(email, password, username) =>
                      this.onDefaultLoginLink(email, password, username)
                    }
                    onUnlink={this.onUnlink}
                  />
                ) : (
                  <SocialLoginToggle
                    onlyOneLeft={onlyOneLeft}
                    signInMethod={signInMethod}
                    isEnabled={isEnabled}
                    onLink={this.handleSocialLoginLink}
                    onUnlink={this.onUnlink}
                  />
                )}
              </li>
            )
          })}
        </UL>
        {error && error.message}
      </div>
    )
  }
}

export const SocialLoginToggle = ({ onlyOneLeft, isEnabled, signInMethod, onLink, onUnlink }) =>
  isEnabled ? (
    <Button2 type="button" onClick={() => onUnlink(signInMethod.id)} disabled={onlyOneLeft}>
      Deactivate {signInMethod.id}
    </Button2>
  ) : (
    <Button type="button" onClick={() => onLink(signInMethod.provider)}>
      Link {signInMethod.id}
    </Button>
  )

export class EmailLoginToggle extends Component {
  constructor(props) {
    super(props)

    this.state = { email: "", username: "", passwordOne: "", passwordTwo: "", error: "" }
  }

  onSubmit = e => {
    e.preventDefault()

    const { email, username, passwordOne, passwordTwo } = this.state

    if (email === "") this.setState({ error: `Bitte füllen Sie das Feld E-Mail-Adresse aus.` })

    if (username === "") this.setState({ error: `Bitte füllen Sie das Feld Name aus.` })

    if (passwordOne === "" || passwordTwo === "")
      this.setState({ error: `Bitte füllen Sie die Passwortfelder aus.` })

    if (passwordOne.length < 6 || passwordTwo.length < 6)
      this.setState({ error: `Das Passwort muss aus mindestens 6 Zeichen bestehen.` })

    if (email === "" && username === "" && passwordOne === "" && passwordTwo === "")
      this.setState({ error: `Bitte füllen Sie alle Felder aus` })

    passwordOne !== passwordTwo
      ? this.setState({
          error: `Die eingegebenen Passwörter stimmen nicht überein`
        })
      : this.props.onLink(email, passwordOne, username).catch(error => this.setState({ error }))

    this.setState({ email: "", username: "", passwordOne: "", passwordTwo: "" })
  }

  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value })
  }

  render() {
    const { onlyOneLeft, isEnabled, signInMethod, onUnlink } = this.props

    const { email, username, passwordOne, passwordTwo, error } = this.state

    return isEnabled ? (
      <Button2 type="button" onClick={() => onUnlink(signInMethod.id)} disabled={onlyOneLeft}>
        Deactivate Email
      </Button2>
    ) : (
      <Form data-form-id="anonymousFormComp" onSubmit={this.onSubmit}>
        {error && <AnonError>{error}</AnonError>}

        <Input
          name="username"
          type="username"
          placeholder="Nachname und Vorname"
          data-input-id="username"
          value={username}
          onChange={this.handleInputChange}
          autoComplete="username"
        />
        <Input
          name="email"
          type="email"
          data-input-id="email"
          value={email}
          onChange={this.handleInputChange}
          placeholder="Email"
          autoComplete="email"
        />
        <Input
          name="passwordOne"
          type="password"
          placeholder="Passwort"
          data-input-id="passwordOne"
          value={passwordOne}
          onChange={this.handleInputChange}
          autoComplete="first-new-password"
        />
        <Input
          name="passwordTwo"
          type="password"
          placeholder="Passwort weiderholen"
          data-input-id="passwordTwo"
          value={passwordTwo}
          onChange={this.handleInputChange}
          autoComplete="second-new-password"
        />
        <AnonButton data-button-id="anonymousSubmitButton" type="submit">
          Link {signInMethod.id}
        </AnonButton>
      </Form>
    )
  }
}

export default withFirebase(LoginManagementBase)
