import React from "react"
import styled from "styled-components"
import { compose } from "recompose"
import LoginManagement from "./loginManagement"
// import Messages from "./messages"
import { PasswordChangeLink } from "./passwordChange"
import { AuthUserContext, WithAuthorization, WithEmailVerification } from "./session"

const H3 = styled.h3`
  font-weight: bold;
  font-family: "overpass";
  color: blue;
  font-style: italic;
`

const DIV = styled.div``
const AccountPageBase = () => (
  <>
    <AuthUserContext.Consumer>
      {authUser => {
        return (
          <DIV>
            {authUser.isAnonymous ? (
              <div>
                <h2>Welcome Guest,</h2>
                <H3>Account: Anonymous</H3>
                {/* <Messages /> */}
                <p>Signup with any of the below links.</p>
                <LoginManagement authUser="anonymous" />
              </div>
            ) : (
              <div>
                <h2>Welcome {authUser.username}</h2>
                <H3>Account: {authUser.email}</H3>
                {/* <Messages /> */}
                <PasswordChangeLink />
                <LoginManagement authUser={authUser} />
              </div>
            )}
          </DIV>
        )
      }}
    </AuthUserContext.Consumer>
  </>
)

const condition = authUser => !!authUser

const AccountPage = compose(
  WithEmailVerification,
  WithAuthorization(condition)
)(AccountPageBase)

export default AccountPage
